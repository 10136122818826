export const VALUE_STREAM_MANAGEMENT_FILTER_GUARDIANS = 'value-stream-management-filter-guardians';
export const SCREEN_POSITION_CONFIGURATION = 'screen-position-configuration';
export const SCREEN_POSITION_MANAGEMENT = 'screen-position-management';

export const SENIORITY_PERSON_REGISTRATION = 'seniority-person-registration';

export const SENIORITY_ADD_EMPLOYEE = 'seniority-add-employee';
export const SENIORITY_EDIT_EMPLOYEE = 'seniority-edit-employee';

export const EMPLOYEE_LOGS_SENIORITY = 'employee-logs-seniority';
export const SENIORITY_PERSONS_REACTIVATE = 'employee-reactivate-seniority';

export const POSITION_ADD_PERSON = 'position-add-person';
export const POSITION_EDIT_PERSON = 'position-edit-person';
